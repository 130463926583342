import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import JSONData from "../json/routinesAll6.json"
import Insta from "../components/cs/instagram-feeds"
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"
   
 

const Bundles = ({ data }) => {
   
   console.log(JSONData)
   
    return(
        
   <Layout>
<Helmet>
        <script src={withPrefix('script.js')} type="text/javascript" />
</Helmet>
<div class="cs-template-content-body">
        <div class="container-fluid cs-page-content">
            <div class="d-flex flex-column align-items-center cs-page-initial">
                <div class="heading">
                    Bundles
                </div>
                <div class="content">
                    Get 3 full size products for $88. <br/><span>Up to $240 Value.</span>
                </div>
                <div class="page-content-img">
                    <img src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/bundles-img.png" alt="" />
                </div>
                <div class="sub-content-bottom">
                    <h6>Try our best selling bundle or customize your own by selecting three items from the product pages.
                    </h6>
                    <a href="javascript:void(0)" onclick="addRoutinesToCartPremade('3', '88', '3', '26');" title="">Shop Bundle - Bestseller</a>
                </div>
            </div>
        </div>
        <div class="container-fluid cs-bundle-features section-hide">
            <div class="row cs-features">
                <div class="col-12 col-md-4">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <div class="img">
                            <img src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/routines/save-money.png"
                                 srcset="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/routines/save-money@2x.png 2x,
                                 https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/routines/save-money@3x.png 3x"
                                 class="cs-img-fluid"/>
                        </div>
                        <div class="content">
                            Bundles allow you to save on skincare—it’s just $88 for three products (save up to $240).
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <div class="img">
                            <img src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/routines/subscribe.png"
                                 srcset="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/routines/subscribe@2x.png 2x,
                                 https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/routines/subscribe@3x.png 3x"
                                 class="cs-img-fluid mx-auto d-block" />
                        </div>
                        <div class="content">
                            Subscribe for automatic monthly delivery, so you never run out of your favorite products.
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <div class="img">
                            <img src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/routines/frequency.png"
                                 srcset="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/routines/frequency@2x.png 2x,
                                 https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/routines/frequency@3x.png 3x"
                                 class="cs-img-fluid mx-auto d-block" />
                        </div>
                        <div class="content">
                            Update your shipping frequency
                            or cancel online at any time.
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="new-cs-bundle-featues-cntnr">
           
            <div class="new-cs-bundle-featues-wrap">
                <div class="new-cs-bundle-featues">
                 
                    <div class="new-cs-bundle-featues-box">
                        <div class="new-cs-bundle-ft-col">
                            <h5>Save on <span>skincare</span></h5>
                            <p>$240 worth of skincare for $88.<br/>Get 3 full size products and save up to $152.</p>
                        </div>
                    </div>
                    <div class="new-cs-bundle-featues-box">
                        <div class="new-cs-bundle-ft-col">
                            <h5>Customize</h5>
                            <p>Create your own bundle from the products page or select from our popular bundles below.</p>
                        </div>
                    </div>
                    <div class="new-cs-bundle-featues-box">
                        <div class="new-cs-bundle-ft-col">
                            <h5><span>Free</span> shipping</h5>
                            <p>Receive free shipping when you purchase a bundle.</p>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div class="skin-concern-cntnt">
            <div class="skin-cncern-hdr" id="skin-cncern-hdrdiv">What’s your <span>skin concern?</span></div>
            <div class="skin-concern-tag">
                <a id="Loss_of_Elasticity_and_Firmness" class="bundleNavitem">Loss of Elasticity & Firmness</a>
                <a id="Lines_and_Wrinkles"  class="bundleNavitem">Lines & Wrinkles</a>
                <a id="Dryness_and_Dehydration"  class="bundleNavitem">Dryness & Dehydration</a>
                <a id="Age_Defense"  class="bundleNavitem">Age Defense</a>
                
            </div>
        </div>


        <div class="bundle-page-bundles">
       
         <div class="product-bundle-container">

            <div class="product-bundle-snippet">
                
                <div class="bundle-stcok">Bundles <span class="totalbundle">(Total 5 of 5)</span></div>
                <div class="bundle-view-all"><a href="javascript:void(0);">View all</a></div>
              
            </div>
            <div class="product-bundle-two-col">
            {
            JSONData.map((data, i) => {     
              return <div class="single-product-bundle-cntnr">  


            
                  <div class="single-product-bundle">
                
                            <div class="single-product-bundle-header">
                                <div class="pb-title">{data.title}</div>
                                <div class="pb-price"><span><strike>${data.price}</strike></span><span class="pb-offer-price">$88</span></div>
                            </div>

                            <div class="single-product-bundle-body">
                                
                                <div class="pb-images">
                                <img src={data.image} alt="" width="350" height="300"/>
                                 
                                        
                                    
                                </div>
                                <div class="pb-advantages">
                                    <ul>
                                        {data.description}
                                    </ul>
                                </div>
                                <div class="pb-shop-cta">
                                    <a href="javascript:void(0);" onclick="addRoutinesToCartPremade('{data.id}', '', '', ');">Shop Bundle — $88</a>
                                </div>


                            </div>

                        </div>
                        </div>
                       i=i+1
                      })} 

                

                
            </div>
            
            <div class="pb-shop-single">
                    <a href="/products">Shop individual products</a>
                </div>
         </div>
        <div class="container-fluid cs-bundle-box">
            <section id="wrap-insta-feed" class="cf">
                <div class="instagram-hashtag-title">
                    <h3>#carrotandstickofficial</h3>
                    <p class="instadescDesktop">Show us how you use our products in your skincare routine<br/>and tag #carrotandstickofficial for your chance to be featured!</p>
                    <p class="instadescMobile">Show us how you are using our products & tag #carrotandstickofficial for a chance to be featured!</p>
                    <div class="igfeeds-custom">
                    


  

                    </div>
                    
                </div>
            </section>
        </div>
    </div>
</div>
   </Layout>

    )
}

export const query = graphql`
  query Bundlequery {
    wpPage(id: { eq: "cG9zdDoxMDUwMg=="}) {
        template {
            ... on WpTemplate_LandingPageThree {
              templateName
              landingPageFields {
                fieldGroupName
                landingPageBodySection {
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleAccordion {
                    fieldGroupName
                    heading
                  }
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleHeroBanner {
                    authorName
                    comment
                  }
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleHeroSection {
                    content
                    fieldGroupName
                    heading
                    logoUrl
                    ctaButton {
                      target
                      title
                      url
                    }
                    features {
                      fieldGroupName
                      label
                    }
                  }
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleLogos {
                    fieldGroupName
                    heading
                  }
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleProductList {
                    fieldGroupName
                    mainProductImageDesktop {
                      altText
                      caption
                      link
                      sourceUrl
                      title
                      uri
                    }
                    productList {
                      fieldGroupName
                      productCategories {
                        fieldGroupName
                        name
                      }
                      productImageMobile {
                        altText
                        title
                        uri
                        sourceUrl
                      }
                      productTitle
                    }
                  }
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleOurVision {
                    content
                    fieldGroupName
                    heading
                    subHeading
                  }
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleProductTestimonials {
                    backgroundColor
                    content
                    fieldGroupName
                    title
                    productTestimonials {
                      authorName
                      comment
                      fieldGroupName
                      rating
                    }
                  }
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleQuickOrder {
                    content
                    fieldGroupName
                    heading
                    image {
                      altText
                      enclosure
                      description
                      link
                      uri
                      title
                    }
                  }
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleTwoColumnBlock {
                    content
                    fieldGroupName
                    heading
                    subHeading
                    image {
                      altText
                      link
                      mediaItemUrl
                      mediaType
                      mimeType
                      fileSize
                      enclosure
                      caption
                      contentType {
                        node {
                          id
                        }
                      }
                    }
                  }
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleBottomStickyProduct {
                    content
                    fieldGroupName
                    buttonCta {
                      target
                      title
                      url
                    }
                    productImage {
                      altText
                      link
                      title
                      uri
                      sourceUrl
                    }
                  }
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleFooterLogo {
                    fieldGroupName
                    logoUrl
                    footerLogo {
                      altText
                      description
                    }
                  }
                  ... on WpTemplate_LandingPageThree_Landingpagefields_LandingPageBodySection_FlexibleTwoColumnBox {
                    fieldGroupName
                    twoColumnBox {
                      backgroundColor
                      backgroundImage {
                        link
                        sourceUrl
                        title
                        uri
                      }
                      productContent
                      productTitle
                      productTag
                      price
                      fieldGroupName
                      productImage {
                        altText
                        link
                        title
                        uri
                        sourceUrl
                      }
                      buttonLink
                      buttonLabel
                    }
                  }
                }
              }
            }
          }
            
        
    }
  }
  
`



export default Bundles
